<div class="layout-wrapper">
  <div
    #layoutContainer
    class="layout-container"
    [ngClass]="{
      'menu-layout-static': !isOverlay(),
      'menu-layout-overlay': isOverlay(),
      'layout-menu-overlay-active': overlayMenuActive,
      'menu-layout-horizontal': isHorizontal(),
      'menu-layout-slim': isSlim(),
      'layout-menu-static-inactive': staticMenuDesktopInactive,
      'layout-menu-static-active': staticMenuMobileActive
    }"
  >
    <app-toolbar></app-toolbar>
    <div
      class="layout-menu"
      [ngClass]="{ 'layout-menu-dark': darkMenu }"
      (click)="onMenuClick($event)"
    >
      <p-scrollPanel #scrollPanel [style]="{ height: '100%' }">
        <app-profile
          *ngIf="profileMode === 'inline' && !isHorizontal()"
        ></app-profile>
        <app-menu [reset]="resetMenu"></app-menu>
      </p-scrollPanel>
    </div>

    <div class="layout-main">
      <app-breadcrumb></app-breadcrumb>
      <div class="layout-content">
        <router-outlet> </router-outlet>
      </div>
      <app-footer></app-footer>
    </div>
  </div>
</div>

<p-toast></p-toast>

<p-confirmDialog header="Confirmação" icon="fa fa-question-circle" #confirmacao>
  <p-footer>
    <button
      type="button"
      pButton
      icon="fa fa-check"
      label="Sim"
      class="p-button-raised p-button-text p-button-plain p-button-sm"
      (click)="confirmacao.accept()"
    ></button>
    <button
      type="button"
      pButton
      icon="fa fa-times"
      label="Não"
      (click)="confirmacao.reject()"
    ></button>
  </p-footer>
</p-confirmDialog>
<app-loading></app-loading>
