import { Rotas } from 'src/app/rotas';

export const MENU = [
  { label: 'Dashboard', icon: 'pi pi-dashboard', routerLink: ['/'] },
  {
    label: 'Cadastros',
    icon: 'pi pi-align-justify',
    items: [
      {
        label: 'Pessoal',
        icon: 'pi pi-align-justify',
        items: [
          {
            label: 'Pessoa',
            icon: 'pi pi-person',
            routerLink: [Rotas.Cadastros.base + Rotas.Cadastros.Pessoa.search],
          },
          {
            label: 'Colaborador',
            icon: 'pi pi-align-justify',
            items: [
              {
                label: 'Setor',
                icon: 'pi pi-align-justify',
                routerLink: [
                  Rotas.Cadastros.base + Rotas.Cadastros.Setor.search,
                ],
              },
              {
                label: 'Cargo',
                icon: 'pi pi-align-justify',
                routerLink: [
                  Rotas.Cadastros.base + Rotas.Cadastros.Cargo.search,
                ],
              },
              {
                label: 'Colaborador',
                icon: 'pi pi-align-justify',
                routerLink: ['/cadastros/pessoal/colaborador'],
              },
            ],
          },
        ],
      },
      {
        label: 'Produtos',
        icon: 'pi pi-align-justify',
        items: [
          {
            label: 'Marca',
            icon: 'pi pi-align-justify',
            routerLink: [Rotas.Cadastros.base + Rotas.Cadastros.Marca.search],
          },
          {
            label: 'UM',
            icon: 'pi pi-align-justify',
            routerLink: [
              Rotas.Cadastros.base + Rotas.Cadastros.UnidadeProduto.search,
            ],
          },
          {
            label: 'Grupo',
            icon: 'pi pi-align-justify',
            routerLink: [Rotas.Cadastros.base + Rotas.Cadastros.Grupo.search],
          },
          {
            label: 'SubGrupo',
            icon: 'pi pi-align-justify',
            routerLink: [
              Rotas.Cadastros.base + Rotas.Cadastros.SubGrupo.search,
            ],
          },
          {
            label: 'Produto',
            icon: 'pi pi-align-justify',
            routerLink: ['/cadastros/produtos'],
          },
          {
            label: 'Tabela Nutricional',
            icon: 'pi pi-align-justify',
            routerLink: [
              Rotas.Cadastros.base + Rotas.Cadastros.TabelaNutricional.search,
            ],
          },
          {
            label: 'Cor',
            icon: 'pi pi-align-justify',
            routerLink: [Rotas.Cadastros.base + Rotas.Cadastros.Cor.search],
          },
          {
            label: 'Tamanho',
            icon: 'pi pi-align-justify',
            routerLink: [Rotas.Cadastros.base + Rotas.Cadastros.Tamanho.search],
          },
          {
            label: 'Almoxarifado',
            icon: 'pi pi-align-justify',
            routerLink: [
              Rotas.Cadastros.base + Rotas.Cadastros.Almoxarifado.search,
            ],
          },
        ],
      },
      {
        label: 'Comerciais',
        icon: 'pi pi-align-justify',
        items: [
          {
            label: 'Atividade',
            icon: 'pi pi-align-justify',
            routerLink: [
              Rotas.Cadastros.base + Rotas.Cadastros.Atividade.search,
            ],
          },
          {
            label: 'Situação',
            icon: 'pi pi-align-justify',
            routerLink: ['/cadastros/comerciais/situacao-for-cli'],
          },
          {
            label: 'Cliente',
            icon: 'pi pi-align-justify',
            routerLink: ['/cadastros/comerciais/cliente'],
          },
          {
            label: 'Fornecedor',
            icon: 'pi pi-align-justify',
            routerLink: ['/cadastros/comerciais/fornecedor'],
          },
          {
            label: 'Vendedor',
            icon: 'pi pi-align-justify',
            routerLink: ['/cadastros/comerciais/vendedor'],
          },
          {
            label: 'Produto promocional',
            icon: 'pi pi-align-justify',
            routerLink: [
              Rotas.Cadastros.base + Rotas.Cadastros.ProdutoPromocional.search,
            ],
          },
          {
            label: 'Tabela de preço',
            icon: 'pi pi-align-justify',
            routerLink: ['/cadastros/comerciais/tabelas-preco'],
          },
          {
            label: 'Condições de pagamento',
            icon: 'pi pi-align-justify',
            routerLink: ['/cadastros/comerciais/condicoes-pagamento'],
          },
          {
            label: 'Região de Venda',
            icon: 'pi pi-align-justify',
            routerLink: ['/cadastros/comerciais/regiao-venda'],
          },
        ],
      },
      {
        label: 'OS',
        icon: 'pi pi-align-justify',
        items: [
          {
            label: 'Equipamentos',
            icon: 'pi pi-align-justify',
            routerLink: ['/cadastros/os/equipamentos'],
          },
          {
            label: 'Tecnico',
            icon: 'pi pi-align-justify',
            routerLink: ['/cadastros/os/tecnicos'],
          },
        ],
      },
      {
        label: 'Financeiro',
        icon: 'pi pi-align-justify',
        items: [
          {
            label: 'Agencia bancaria',
            icon: 'pi pi-align-justify',
            routerLink: ['/cadastros/financeiro/agencias-bancaria'],
          },
          {
            label: 'Conta caixa',
            icon: 'pi pi-align-justify',
            routerLink: ['/cadastros/financeiro/contas-caixa'],
          },
          {
            label: 'Plano Natureza Fin.',
            icon: 'pi pi-align-justify',
            routerLink: ['/cadastros/financeiro/plano-natureza'],
          },
          {
            label: 'Operadora cartão',
            icon: 'pi pi-align-justify',
            routerLink: ['/cadastros/financeiro/operadoras-cartao'],
          },
          {
            label: 'Natureza Financeira',
            icon: 'pi pi-align-justify',
            routerLink: ['/cadastros/financeiro/natureza-financeira'],
          },
          {
            label: 'Tipo de recebimento',
            icon: 'pi pi-align-justify',
            routerLink: ['/cadastros/financeiro/tipos-recebimento'],
          },
          {
            label: 'Tipo de pagamento',
            icon: 'pi pi-align-justify',
            routerLink: ['/cadastros/financeiro/tipos-pagamento'],
          },
          {
            label: 'Talonário Cheque',
            icon: 'pi pi-align-justify',
            routerLink: ['/cadastros/financeiro/talonario-cheque'],
          },
          {
            label: 'Cheque',
            icon: 'pi pi-align-justify',
            routerLink: ['/cadastros/financeiro/cheque'],
          },
        ],
      },
      {
        label: 'Transporte',
        icon: 'pi pi-align-justify',
        items: [
          {
            label: 'Transportadora',
            icon: 'pi pi-align-justify',
            routerLink: ['/cadastros/transporte/transportadora'],
          },
          {
            label: 'Veiculo',
            icon: 'pi pi-align-justify',
            routerLink: ['/cadastros/transporte/veiculo'],
          },
        ],
      },
      {
        label: 'PDV',
        icon: 'pi pi-align-justify',
        items: [
          {
            label: 'Caixa',
            icon: 'pi pi-align-justify',
            routerLink: ['/cadastros/pdv/caixa'],
          },
          {
            label: 'Operador',
            icon: 'pi pi-align-justify',
            routerLink: ['/cadastros/pdv/operador'],
          },
        ],
      },
      {
        label: 'Administrativos',
        icon: 'pi pi-align-justify',
        items: [
          {
            label: 'Contador',
            icon: 'pi pi-align-justify',
            routerLink: ['/cadastros/administrativos/contador'],
          },
          {
            label: 'Empresa',
            icon: 'pi pi-align-justify',
            routerLink: ['/cadastros/administrativos/empresa'],
          },
          {
            label: 'Papel',
            icon: 'pi pi-align-justify',
            routerLink: ['/cadastros/administrativos/papel'],
          },
          {
            label: 'Usuário',
            icon: 'pi pi-align-justify',
            routerLink: [Rotas.Cadastros.base + Rotas.Cadastros.Usuario.search],
          },
        ],
      },
    ],
  },

  {
    label: 'Financeiro',
    icon: 'pi pi-align-justify',
    items: [
      {
        label: 'Lançamentos',
        icon: 'pi pi-align-justify',
        items: [
          {
            label: 'Receber',
            icon: 'pi pi-align-justify',
            items: [
              {
                label: 'Clientes',
                icon: 'pi pi-align-justify',
                routerLink: ['/financeiro/lancamentos/receber'],
              },
              {
                label: 'Cartão',
                icon: 'pi pi-align-justify',
                routerLink: ['/financeiro/lancamentos/receber/cartoes'],
              },
            ],
          },
          {
            label: 'Pagar',
            icon: 'pi pi-align-justify',
            routerLink: [
              Rotas.Financeiro.base + Rotas.Financeiro.LancamentoPagar.search,
            ],
          },
        ],
      },
      {
        label: 'Recebimento',
        icon: 'pi pi-align-justify',
        routerLink: ['/financeiro/recebimento'],
      },
    ],
  },
  {
    label: 'Comercial',
    icon: 'pi pi-align-justify',
    items: [
      {
        label: 'Orçamento',
        icon: 'pi pi-align-justify',
        routerLink: [Rotas.Comercial.base + Rotas.Comercial.Orcamento.search],
      },
      {
        label: 'Pedido',
        icon: 'pi pi-align-justify',
        routerLink: [Rotas.Comercial.base + Rotas.Comercial.Pedido.search],
      },
      {
        label: 'Pré-venda',
        icon: 'pi pi-align-justify',
        routerLink: [Rotas.Comercial.base + Rotas.Comercial.PreVenda.search],
      },
      {
        label: 'OS',
        icon: 'pi pi-align-justify',
        routerLink: ['/comercial/os'],
      },
      {
        label: 'PDV',
        icon: 'pi pi-align-justify',
        routerLink: ['/comercial/pdv'],
      },
      {
        label: 'NF-e',
        icon: 'pi pi-align-justify',
        routerLink: ['/comercial/nfe'],
      },
      {
        label: 'MDF-e',
        icon: 'pi pi-align-justify',
        routerLink: ['/comercial/mdfe'],
      },
    ],
  },
  {
    label: 'Suprimentos',
    icon: 'pi pi-align-justify',
    items: [
      {
        label: 'Estoque',
        icon: 'pi pi-align-justify',
        items: [
          {
            label: 'Formação Preço',
            icon: 'pi pi-align-justify',
            routerLink: ['/suprimentos/estoque/formacao-preco'],
          },
          {
            label: 'Requisição Interna',
            icon: 'pi pi-align-justify',
            routerLink: [
              Rotas.Suprimentos.base +
                Rotas.Suprimentos.Estoque.RequisicaoInterna.search,
            ],
          },
          {
            label: 'Ajuste',
            icon: 'pi pi-align-justify',
            routerLink: ['/suprimentos/estoque/ajuste'],
          },
          {
            label: 'Reajuste',
            icon: 'pi pi-align-justify',
            routerLink: ['/suprimentos/estoque/reajuste'],
          },
          {
            label: 'Entrada',
            icon: 'pi pi-align-justify',
            routerLink: ['/suprimentos/estoque/entradas'],
          },
          {
            label: 'Transferencia',
            icon: 'pi pi-align-justify',
            routerLink: ['/suprimentos/estoque/transferencia'],
          },
          {
            label: 'Balanço',
            icon: 'pi pi-align-justify',
            routerLink: ['/suprimentos/estoque/balanco'],
          },
          {
            label: 'Movimentação',
            icon: 'pi pi-align-justify',
            routerLink: ['/suprimentos/estoque/movimentacao'],
          },
        ],
      },
      {
        label: 'Compras',
        icon: 'pi pi-align-justify',
        items: [
          {
            label: 'Fornecedor x Produto',
            icon: 'pi pi-align-justify',
            routerLink: ['/suprimentos/compras/fornecedor-produto'],
          },
          {
            label: 'Requisição',
            icon: 'pi pi-align-justify',
            routerLink: ['/suprimentos/compras/requisicao'],
          },
          {
            label: 'Cotação',
            icon: 'pi pi-align-justify',
            routerLink: ['/suprimentos/compras/cotacao'],
          },
          {
            label: 'Mapa Comparativo',
            icon: 'pi pi-align-justify',
            routerLink: ['/suprimentos/compras/mapa-comparativo'],
          },
          {
            label: 'Pedido',
            icon: 'pi pi-align-justify',
            routerLink: ['/suprimentos/compras/pedido'],
          },
          {
            label: 'Compra Sugerida',
            icon: 'pi pi-align-justify',
            routerLink: ['/suprimentos/compras/compra-sugerida'],
          },
          {
            label: 'Devolução',
            icon: 'pi pi-align-justify',
            routerLink: ['/suprimentos/compras/devolucao'],
          },
        ],
      },
    ],
  },
  {
    label: 'Fiscal',
    icon: 'pi pi-align-justify',
    items: [
      {
        label: 'Tributação',
        icon: 'pi pi-align-justify',
        items: [
          {
            label: 'Grupo Tributário',
            icon: 'pi pi-align-justify',
            routerLink: ['/fiscal/tributacao/grupo-tributario'],
          },
          {
            label: 'Operação Fiscal',
            icon: 'pi pi-align-justify',
            routerLink: ['/fiscal/tributacao/operacoes-fiscais'],
          },
          {
            label: 'Configurar tributação',
            icon: 'pi pi-align-justify',
            routerLink: [
              Rotas.Fiscal.base + Rotas.Fiscal.ConfigurarTributação.search,
            ],
          },
          {
            label: 'IBPT',
            icon: 'pi pi-align-justify',
            routerLink: [Rotas.Fiscal.base + Rotas.Fiscal.Ibpt.search],
          },
        ],
      },
      {
        label: 'Apuração',
        icon: 'pi pi-align-justify',
        items: [
          {
            label: 'ICMS',
            icon: 'pi pi-align-justify',
            routerLink: ['/fiscal/apuracao/icms'],
          },
        ],
      },
      {
        label: 'SPED',
        icon: 'pi pi-align-justify',
        items: [
          {
            label: 'Fiscal',
            icon: 'pi pi-align-justify',
            routerLink: ['/fiscal/sped/efd-icms'],
          },
          {
            label: 'Contribuições',
            icon: 'pi pi-align-justify',
            routerLink: ['/fiscal/sped/efd-contribuicoes'],
          },
        ],
      },
    ],
  },
  {
    label: 'Configurações',
    icon: 'pi pi-align-justify',
    items: [
      {
        label: 'Parâmetros',
        icon: 'pi pi-align-justify',
        routerLink: ['/configuracao/parametros'],
      },
      {
        label: 'Certificado',
        icon: 'pi pi-align-justify',
        routerLink: ['/configuracao/certificado'],
      },
      {
        label: 'NF-e',
        icon: 'pi pi-align-justify',
        routerLink: ['/configuracao/nfe'],
      },
      {
        label: 'MDF-e',
        icon: 'pi pi-align-justify',
        routerLink: ['/configuracao/mdfe'],
      },
      {
        label: 'PDV',
        icon: 'pi pi-align-justify',
        routerLink: ['/configuracao/pdv'],
      },
      {
        label: 'OS',
        icon: 'pi pi-align-justify',
        routerLink: ['/configuracao/os'],
      },
      {
        label: 'Modelo Doc. Fiscais',
        icon: 'pi pi-align-justify',
        routerLink: ['/configuracao/modelo-doc-fiscal'],
      },
      {
        label: 'Conversão CFOP',
        icon: 'pi pi-align-justify',
        routerLink: ['/configuracao/conversoes-cfop'],
      },
      {
        label: 'Conversão CST',
        icon: 'pi pi-align-justify',
        routerLink: ['/configuracao/conversoes-cst'],
      },
    ],
  },
];
