import { HttpClientJsonpModule, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BreadcrumbService } from 'src/app/layout/service/breadcrumb.service';
import { BlobErrorHttpInterceptor } from '../interception/blobErrorHttp.interceptor';
import { LoadingInterceptor } from '../interception/loading.interceptor';
import { LoadingService } from '../layout/service/loading.service';
import { SharedModule } from '../shared/shared.module';
import { ChronosHttp } from './../seguranca/chronos-http';
import { AcessoNegadoComponent } from './acesso-negado/acesso-negado.component';
import { PaginaNaoEncontradaComponent } from './pagina-nao-encontrada/pagina-nao-encontrada.component';



@NgModule({
  imports: [
    HttpClientModule,
    HttpClientJsonpModule,
    RouterModule,
    SharedModule,

  ],
  exports: [
    BrowserAnimationsModule,

  ],
  providers: [
    Title,
    ConfirmationService,
    MessageService,
    ChronosHttp,
    BreadcrumbService,
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: (service: LoadingService) => new LoadingInterceptor(service),
      multi: true,
      deps: [LoadingService]
    },
    { provide: HTTP_INTERCEPTORS, useClass: BlobErrorHttpInterceptor, multi: true }

  ],
  declarations: [PaginaNaoEncontradaComponent, AcessoNegadoComponent]
})
export class CoreModule {

  constructor(
    @Optional() @SkipSelf() parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only.');
    }
  }

}
