export const Rotas = {
  Cadastros: {
    base: '/cadastros/',
    Marca: {
      search: 'produtos/marcas',
      update: 'produtos/marcas/:id',
      create: 'produtos/marcas/nova',
    },
    UnidadeProduto: {
      search: 'produtos/unidade-produtos',
      update: 'produtos/unidade-produtos/:id',
      create: 'produtos/unidade-produtos/nova',
    },
    Grupo: {
      search: 'produtos/grupos',
      update: 'produtos/grupos/:id',
      create: 'produtos/grupos/nova',
    },
    SubGrupo: {
      search: 'produtos/subGrupos',
      update: 'produtos/subGrupos/:id',
      create: 'produtos/subGrupos/nova',
    },
    Produtos: {
      search: 'produtos',
      update: 'produtos/:id',
      create: 'produtos/nova',
    },
    Cor: {
      search: 'produtos/cores',
      update: 'produtos/cores/:id',
      create: 'produtos/cores/nova',
    },
    Tamanho: {
      search: 'produtos/tamanhos',
      update: 'produtos/tamanhos/:id',
      create: 'produtos/tamanhos/nova',
    },
    Almoxarifado: {
      search: 'produtos/almoxarifados',
      update: 'produtos/almoxarifados/:id',
      create: 'produtos/almoxarifados/nova',
    },
    TabelaNutricional: {
      search: 'produtos/tabelaNutricional',
      update: 'produtos/tabelaNutricional/:id',
      create: 'produtos/tabelaNutricional/nova',
    },
    Usuario: {
      search: 'administrativos/usuarios',
      update: 'administrativos/usuarios/:id',
      create: 'administrativos/usuarios/novo',
    },
    Pessoa: {
      search: 'pessoal/pessoas',
      update: 'pessoal/pessoas/:id',
      create: 'pessoal/pessoas/nova',
    },
    Setor: {
      search: 'pessoal/setores',
      update: 'pessoal/setores/:id',
      create: 'pessoal/setores/novo',
    },
    Cargo: {
      search: 'pessoal/cargos',
      update: 'pessoal/cargos/:id',
      create: 'pessoal/cargos/novo',
    },
    Atividade: {
      search: 'comercial/atividades',
      update: 'comercial/atividades/:id',
      create: 'comercial/atividades/nova',
    },
    ProdutoPromocional: {
      search: 'comercial/produtos-promocionais',
      update: 'comercial/produtos-promocionais/:id',
      create: 'comercial/produtos-promocionais/novo',
    },
  },
  Comercial: {
    base: '/comercial/',
    PreVenda: {
      search: 'pre-vendas',
      update: 'pre-vendas/:id',
      create: 'pre-vendas/nova',
    },
    Orcamento: {
      search: 'orcamentos',
      update: 'orcamentos/:id',
      create: 'orcamentos/novo',
    },
    Pedido: {
      search: 'pedidos',
      update: 'pedidos/:id',
      create: 'pedidos/novo',
    },
  },
  Financeiro: {
    base: '/financeiro/',
    LancamentoPagar: {
      search: 'lancamentos-pagar',
      update: 'lancamentos-pagar/:id',
      create: 'lancamentos-pagar/novo',
    },
  },
  Suprimentos: {
    base: '/suprimentos/',
    Estoque: {
      Entrada: {
        search: 'estoque/entradas',
        update: 'estoque/entradas/:id',
        create: 'estoque/entradas/nova',
      },
      RequisicaoInterna: {
        search: 'estoque/requisicoes-interna',
        update: 'estoque/requisicoes-interna/:id',
        create: 'estoque/requisicoes-interna/nova',
      },
    },
  },
  Fiscal: {
    base: '/fiscal/',
    ConfigurarTributação: {
      search: 'tributacao/configuracoes',
      update: 'tributacao/configuracoes/:id',
      create: 'tributacao/configuracoes/nova',
    },
    Ibpt: {
      search: 'tributacao/ibpt',
    },
  },
};
