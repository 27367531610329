<div class="login-image"></div>

<div class="card login-panel p-fluid">
  <div class="login-panel-content">
    <form [formGroup]="loginForm" (onsubmit)="login()">
      <div class="p-grid">
        <div class="p-col-3" style="text-align:left;">
          <img src="assets/layout/images/login/icon-login.svg" />
        </div>
        <div class="p-col-9" style="text-align:right;">
          <h2 class="welcome-text">ChronosINFO</h2>
          <span class="guest-sign-in">Proporcionando uma boa experiência</span>
        </div>
        <div class="p-col-12" style="text-align:left;">
          <label class="label-control">Usuário</label>
          <div class="login-input">
            <input type="text" formControlName="email" pInputText />
          </div>
        </div>
        <div class="p-col-12" style="text-align:left;">
          <label class="label-control">Senha</label>
          <div class="login-input">
            <input type="password" formControlName="senha" pInputText />
          </div>
        </div>
        <div class="p-col-6 button-pane">
          <button type="submit" pButton label="Login" [disabled]="!loginForm.valid" (click)="login()"
            *ngIf="!submentendo">
          </button>
          <p-progressSpinner strokeWidth="8" [style]="{width: '50px', height: '50px'}" *ngIf="submentendo">
          </p-progressSpinner>
        </div>
        <div class="p-col-6 link-pane">
          <a href="#">Suporte?</a>
        </div>
      </div>
    </form>
  </div>
</div>
<p-toast></p-toast>
