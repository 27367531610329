import { Component, OnInit } from "@angular/core";
import { ClockService } from "../service/clock.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  dataAtual: Date = new Date();

  constructor(private service: ClockService) {}

  ngOnInit() {
    this.service.getClock().subscribe((time) => (this.dataAtual = time));
  }
}
