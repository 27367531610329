import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { LoginGuard } from './login.guard';



const routes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [ LoginGuard ]  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [ LoginGuard ]
})
export class SegurancaRoutingModule { }
