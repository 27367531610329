import { Component, OnInit, Renderer2 } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ErrorHandlerService } from "src/app/core/service/error-handler.service";
import { AuthService } from "../auth.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  loginForm = new UntypedFormGroup({});

  submentendo = false;

  constructor(
    private renderer: Renderer2,
    private fb: UntypedFormBuilder,
    private auth: AuthService,
    private router: Router,
    private errorHandle: ErrorHandlerService
  ) {}

  ngOnInit() {
    this.loginForm = this.fb.group({
      email: this.fb.control("", [Validators.required, Validators.email]),
      senha: this.fb.control("", [Validators.required]),
    });

    console.log("teste pagina login");
    if (!this.auth.isAccessTokenInvalido()) {
      this.router.navigate(["/dashboard"]);
    }
  }

  login() {
    this.submentendo = true;
    this.auth
      .login(this.loginForm.value.email, this.loginForm.value.senha)
      .then(() => {
        const redirect: string = this.auth.redirectUrl || "/dashboard";
        this.router.navigate([redirect]);

        this.submentendo = false;
      })
      .catch((erro) => {
        this.submentendo = false;
        this.errorHandle.handleError(erro);
      });
  }
}
