import { NgModule } from '@angular/core';
import { LoginComponent } from './login/login.component';
import { SharedModule } from '../shared/shared.module';
import { SegurancaRoutingModule } from './seguranca-routing.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './Jwt-interceptor';

import {ToastModule} from 'primeng/toast';

export function tokenGetter() {
  return localStorage.getItem('chronos-erp-token');
}


@NgModule({
  imports: [
    SharedModule,
    ToastModule,
    SegurancaRoutingModule
  ],
  declarations: [LoginComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }
  ],
})
export class SegurancaModule { }
