<div class="footer fixed">

  <div>
    <strong>Copyright </strong> ChronosInfo &copy; 2021
    <strong>Versão 1.0.0</strong>
  </div>

  <div class="pull-right">
    <strong>{{dataAtual|date:'dd/MM/yyyy hh:MM:ss'}}</strong>
  </div>

</div>
