import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { NgxCurrencyModule } from 'ngx-currency';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ColorPickerModule } from 'primeng/colorpicker';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KeyFilterModule } from 'primeng/keyfilter';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { TooltipModule } from 'primeng/tooltip';
import { LayoutModule } from '../layout/layout.module';
import { BotaoTelaPesquisaComponent } from './botao-tela-pesquisa/botao-tela-pesquisa.component';
import { AutoCompleteComponent } from './components/auto-complete/auto-complete.component';
import { BadgeComponent } from './components/badge/badge.component';
import { BotaoFormularioComponent } from './components/botao-formulario/botao-formulario.component';
import { BoxValueComponent } from './components/box-value/box-value.component';
import { CepComponent } from './components/cep/cep.component';
import { ContainerInputComponent } from './components/container-input/container-input.component';
import { ErrorComponent } from './components/error/error.component';
import { FormBoxComponent } from './components/form-box/form-box.component';
import { AlmoxarifadoAutoCompleteComponent } from './components/form/auto-complete/almoxarifado-auto-complete/almoxarifado-auto-complete.component';
import { ClienteAutoCompleteComponent } from './components/form/auto-complete/cliente-auto-clomplete/cliente-auto-complete.component';
import { ColaboradorAutoCompleteComponent } from './components/form/auto-complete/colaborador-auto-complete/colaborador-auto-complete.component';
import { ContaCaixaAutoCompleteComponent } from './components/form/auto-complete/conta-caixa-auto-complete/conta-caixa-auto-complete.component';
import { DocumentoOrigemAutoCompleteComponent } from './components/form/auto-complete/documento-origem-auto-complete/documento-origem-auto-complete.component';
import { FornecedorAutoCompleteComponent } from './components/form/auto-complete/fornecedor-auto-complete/fornecedor-auto-complete.component';
import { GrupoProdutoAutoCompleteComponent } from './components/form/auto-complete/grupo-produto-auto-complete/grupo-produto-auto-complete.component';
import { GrupoTributarioAutoCompleteComponent } from './components/form/auto-complete/grupo-tributario-auto-complete/grupo-tributario-auto-complete.component';
import { MarcaProdutoAutoCompleteComponent } from './components/form/auto-complete/marca-produto-auto-complete/marca-produto-auto-complete.component';
import { NaturezaFinanceiraAutoCompleteComponent } from './components/form/auto-complete/natureza-financeira-auto-complete/natureza-financeira-auto-complete.component';
import { ProdutoAutoCompleteComponent } from './components/form/auto-complete/produto-auto-complete/produto-auto-complete.component';
import { SubGrupoProdutoAutoCompleteComponent } from './components/form/auto-complete/sub-grupo-produto-auto-complete/sub-grupo-produto-auto-complete.component';
import { UnidadeMedidaAutoCompleteComponent } from './components/form/auto-complete/unidade-medida-auto-complete/unidade-medida-auto-complete.component';
import { VendedorAutoCompleteComponent } from './components/form/auto-complete/vendedor-auto-complete/vendedor-auto-complete.component';
import { FormularioComponent } from './components/formulario/formulario.component';
import { IboxContentComponent } from './components/ibox-content/ibox-content.component';
import { IconButtonComponent } from './components/icon-button/icon-button.component';
import { InputAreaComponent } from './components/input-area/input-area.component';
import { InputCnpjComponent } from './components/input-cnpj/input-cnpj.component';
import { InputCpfComponent } from './components/input-cpf/input-cpf.component';
import { InputDateComponent } from './components/input-date/input-date.component';
import { InputIntegerLabelComponent } from './components/input-integer-label/input-integer-label.component';
import { InputIntegerComponent } from './components/input-integer/input-integer.component';
import { InputMaskComponent } from './components/input-masck/input-mask.component';
import { InputMoneyComponent } from './components/input-money/input-money.component';
import { InputTextActionComponent } from './components/input-text-action/input-text-action.component';
import { InputTextLabelComponent } from './components/input-text-label/input-text-label.component';
import { InputTextComponent } from './components/input-text/input-text.component';
import { InputUploadComponent } from './components/input-upload/input-upload.component';
import { ListBasicComponent } from './components/list-basic/list-basic.component';
import { ListComponent } from './components/list/list.component';
import { SelectBasicActionComponent } from './components/select-basic-action/select-basic-action.component';
import { SelectBasicLabelComponent } from './components/select-basic-label/select-basic-label.component';
import { SelectBasicComponent } from './components/select-basic/select-basic.component';
import { TablePaginadaComponent } from './components/table-paginada/table-paginada.component';
import { TableComponent } from './components/table/table.component';
import { TelaPesquisaComponent } from './components/tela-pesquisa/tela-pesquisa.component';
import { BaseTemplateDirective } from './diretivas/base-template.directive';
import { CnpjPipe } from './pipes/cnpj.pipe';
import { CompletarZerosPipe } from './pipes/completar-zeros.pipe';
import { CpfPipe } from './pipes/cpf.pipe';
import { CpfCnpjPipe } from './pipes/cpfCnpj.pipe';

export const customCurrencyMaskConfig = {
  align: 'right',
  allowNegative: true,
  allowZero: true,
  decimal: ',',
  precision: 2,
  prefix: '',
  suffix: '',
  thousands: '.',
  nullable: true,
};

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    InputTextModule,
    InputTextareaModule,
    InputNumberModule,
    InputMaskModule,
    TableModule,
    FormsModule,
    ReactiveFormsModule,
    AutoCompleteModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    CalendarModule,
    TieredMenuModule,
    FileUploadModule,
    TextMaskModule,
    PickListModule,
  ],
  declarations: [
    FormularioComponent,
    BotaoFormularioComponent,
    BotaoTelaPesquisaComponent,
    ContainerInputComponent,
    ErrorComponent,
    InputTextLabelComponent,
    InputAreaComponent,
    InputDateComponent,
    TelaPesquisaComponent,
    SelectBasicComponent,
    AutoCompleteComponent,
    InputMoneyComponent,
    ListBasicComponent,
    ListComponent,
    BoxValueComponent,
    InputMaskComponent,
    CompletarZerosPipe,
    CpfCnpjPipe,
    CpfPipe,
    CnpjPipe,
    CepComponent,
    InputCpfComponent,
    InputCnpjComponent,
    InputTextActionComponent,
    InputTextComponent,
    InputIntegerComponent,
    InputIntegerLabelComponent,
    SelectBasicLabelComponent,
    SelectBasicActionComponent,
    IconButtonComponent,
    BaseTemplateDirective,
    BadgeComponent,
    FormBoxComponent,
    InputUploadComponent,
    IboxContentComponent,
    TablePaginadaComponent,

    ClienteAutoCompleteComponent,
    VendedorAutoCompleteComponent,
    GrupoProdutoAutoCompleteComponent,

    SubGrupoProdutoAutoCompleteComponent,
    UnidadeMedidaAutoCompleteComponent,
    MarcaProdutoAutoCompleteComponent,
    AlmoxarifadoAutoCompleteComponent,
    GrupoTributarioAutoCompleteComponent,
    ColaboradorAutoCompleteComponent,
    TableComponent,
    ContaCaixaAutoCompleteComponent,
    NaturezaFinanceiraAutoCompleteComponent,
    FornecedorAutoCompleteComponent,
    DocumentoOrigemAutoCompleteComponent,
    ProdutoAutoCompleteComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CepComponent,
    FormularioComponent,
    BotaoTelaPesquisaComponent,
    BotaoFormularioComponent,
    ContainerInputComponent,
    LayoutModule,

    NgxCurrencyModule,

    ButtonModule,
    IconButtonComponent,
    DropdownModule,
    InputTextModule,
    AutoCompleteModule,
    InputTextareaModule,
    TooltipModule,
    ButtonModule,
    TableModule,
    TabViewModule,
    AutoCompleteModule,
    DropdownModule,
    CalendarModule,
    DialogModule,
    KeyFilterModule,
    ProgressSpinnerModule,
    FileUploadModule,
    InputMaskModule,
    FieldsetModule,
    SplitButtonModule,
    EditorModule,
    InputTextLabelComponent,
    InputAreaComponent,
    TelaPesquisaComponent,
    SelectBasicComponent,
    AutoCompleteComponent,
    InputMoneyComponent,
    InputDateComponent,
    InputTextActionComponent,
    InputIntegerLabelComponent,
    SelectBasicActionComponent,
    SelectBasicLabelComponent,
    ListComponent,
    ListBasicComponent,
    InputMaskComponent,
    BoxValueComponent,
    CompletarZerosPipe,
    CpfCnpjPipe,
    CpfPipe,
    CnpjPipe,
    InputNumberModule,
    FormBoxComponent,
    TieredMenuModule,
    BadgeComponent,
    InputUploadComponent,
    PickListModule,
    RadioButtonModule,
    CheckboxModule,
    IboxContentComponent,
    ColorPickerModule,
    VendedorAutoCompleteComponent,
    ClienteAutoCompleteComponent,
    BaseTemplateDirective,
    TablePaginadaComponent,
    GrupoProdutoAutoCompleteComponent,
    SubGrupoProdutoAutoCompleteComponent,
    UnidadeMedidaAutoCompleteComponent,
    MarcaProdutoAutoCompleteComponent,
    AlmoxarifadoAutoCompleteComponent,
    GrupoTributarioAutoCompleteComponent,
    ColaboradorAutoCompleteComponent,
    ContaCaixaAutoCompleteComponent,
    NaturezaFinanceiraAutoCompleteComponent,
    FornecedorAutoCompleteComponent,
    DocumentoOrigemAutoCompleteComponent,
    TableComponent,
    PasswordModule,
    ProdutoAutoCompleteComponent,
  ],
})
export class SharedModule {}
