import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AcessoNegadoComponent } from './core/acesso-negado/acesso-negado.component';
import { AuthGuard } from './seguranca/auth.guard';

const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'cadastros',
    loadChildren: () =>
      import('./view/cadastros/cadastros.module').then(
        (m) => m.CadastrosModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'financeiro',
    loadChildren: () =>
      import('./view/financeiro/financeiro.module').then(
        (m) => m.FinanceiroModule
      ),
  },
  {
    path: 'comercial',
    loadChildren: () =>
      import('./view/comercial/comercial.module').then(
        (m) => m.ComercialModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'suprimentos',
    loadChildren: () =>
      import('./view/suprimentos/suprimento.module').then(
        (m) => m.SuprimentoModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'fiscal',
    loadChildren: () =>
      import('./view/fiscal/fiscal.module').then((m) => m.FiscalModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'gerencia',
    loadChildren: () =>
      import('./view/gerencial/gerencial.module').then(
        (m) => m.GerencialModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'configuracao',
    loadChildren: () =>
      import('./view/configuracao/configuracao.module').then(
        (m) => m.ConfiguracaoModule
      ),
    canActivate: [AuthGuard],
  },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'acesso-negado', component: AcessoNegadoComponent },
  // { path: 'pagina-nao-encontrada', component: PaginaNaoEncontradaComponent },
  // { path: '**', redirectTo: 'pagina-nao-encontrada' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
